import Formation from '../images/circle-formation.png'
import Communication from '../images/circle-communication.png'
import Multimedia from '../images/circle-multimedia.png'
import FormationHover from '../images/circle-formation-hover.png'
import CommunicationHover from '../images/circle-communication-hover.png'
import MultimediaHover from '../images/circle-multimedia-hover.png'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Plus from '../images/plus.png'

const ServicesComp = (props) => {
    return (
        <div className='serviceWrapper'>
            <div id='circleAndLetter'>
                <img className='circleImage' src={props.roundLogo} alt='Logo ALGEGO' />
                <img className='circleImageHover' src={props.roundLogoHover} alt='Logo ALGEGO' />
            </div>
            <div className='shortDescription'><p>{props.shortDescription}</p></div>
            <div className='longDescription'>
                <div className='longDesText'>
                    {props.longDescription}
                </div>
            </div>
        </div>
    )
}

const longDescriptionFormation =
    <><p>Nous créons des<strong> expériences d’apprentissage en ligne</strong> sur mesure favorisant l’immersion et l’engagement de vos collaborateurs.</p><div className='plusWrapper'><span>Le couteau suisse de vos formations</span><a href="/formations"><div className='plusImgWrapper'><img src={Plus} alt='Plus'></img></div></a></div></>

const longDescriptionCommunication =
    <><p>Nous déployons des <strong>stratégies innovantes</strong> pour dynamiser vos communications internes et externes, et la gestion du changement.</p><div className='plusWrapper'><span>Découvrez nos réalisations</span><a href="/realisations"><div className='plusImgWrapper'><img src={Plus} alt='Plus'></img></div></a></div></>

const longDescriptionMultimedia =
    <><p>Quels que soient vos projets (formation, communication, événements), le <strong>multimédia</strong> est toujours intégré dans nos <strong>solutions innovantes</strong>.</p><div className='plusWrapper'><span>Découvrez nos réalisations</span><a href="/realisations"><div className='plusImgWrapper'><img src={Plus} alt='Plus'></img></div></a></div></>

function Services() {
    AOS.init();
    return (
        <>
            <div className='row'>
                <div className='col-xl-4 col-lg-12' data-aos='slide-right' data-aos-duration='1000'>
                    <ServicesComp roundLogo={Formation} roundLogoHover={FormationHover} longDescription={longDescriptionFormation} shortDescription='Formation' />
                </div>

                <div className='col-xl-4 col-lg-12' data-aos='fade-up' data-aos-duration='1000' data-aos-delay='400'>
                    <ServicesComp roundLogo={Communication} roundLogoHover={CommunicationHover} longDescription={longDescriptionCommunication} shortDescription='Communication' />
                </div>

                <div className='col-xl-4 col-lg-12' data-aos='slide-left' data-aos-duration='1000'>
                    <ServicesComp roundLogo={Multimedia} roundLogoHover={MultimediaHover} longDescription={longDescriptionMultimedia} shortDescription='Multimédia' />
                </div>
            </div>
        </>
    )

}

export default Services;