import Typewriter from "typewriter-effect"

export default function FormationsTypeWriter() {
    return (
            <Typewriter
                options={{
                    delay: 100,
                }}
                onInit={(typewriter) => {
                    typewriter
                        .typeString("Le couteau suisse<br>de vos formations")
                        .start();
                }}
            />
    );
}